import React, { useContext, useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import MenuCard from '../../components/UI/Card/MenuCard';
import { NetfrontServices } from '../../constants/NetfrontServices';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useNewEkardoSiteUrls, useSchedulerSiteUrls } from '../../hooks';
import analytics from '../../static/img/analytics.svg';
import contentImage from '../../static/img/content.svg';
import libraryImage from '../../static/img/library.svg';
import notificationImage from '../../static/img/notifications.svg';
import reportingImage from '../../static/img/reporting.svg';
import IconSocial from '../../static/img/social-icon.svg';
import subImage from '../../static/img/subscriptions.svg';
import userImage from '../../static/img/users.svg';

export const CardBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -1.7em;
`;

function ProjectConsolePage() {
  const { project } = useContext(AppContext);

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getIdentitySiteBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { pathname } = useLocation();
  const params = useParams();

  const { getBaseUrl: getSchedulerSiteBaseUrl } = useSchedulerSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_SCHEDULER_SITE_LOCAL_PORT,
  });

  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_SCHEDULER_SITE_LOCAL_PORT,
  });

  const { projectId, orgId } = params;

  const [featureCards, setFeatureCards] = useState([]);
  const [identitySiteUrl, setIdentitySiteUrl] = useState();
  const [schedulerSiteUrl, setSchedulerSiteUrl] = useState();
  const [newEkardoUrl, setNewEkardoUrl] = useState();

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentitySiteUrl(getIdentitySiteBaseUrl());
    setSchedulerSiteUrl(getSchedulerSiteBaseUrl());
    setNewEkardoUrl(getNewEkardoSiteBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!(identitySiteUrl && project && schedulerSiteUrl)) {
      return;
    }

    const { services = [] } = project;

    setFeatureCards([
      {
        image: contentImage,
        isVisible: services.includes('EKARDO'),
        key: 'content',
        path: 'group',
        title: 'Content',
      },
      {
        image: libraryImage,
        isVisible: services.includes('EKARDO'),
        key: 'library',
        path: 'library',
        title: 'Library',
      },
      {
        href: `${identitySiteUrl}/project/${projectId}/user`,
        image: userImage,
        isVisible: true,
        key: 'users',
        title: 'Users',
      },
      {
        image: subImage,
        isVisible: services.includes(NetfrontServices.MURIQUI) && services.includes(NetfrontServices.VERVET),
        key: 'subscriptions',
        path: 'subscriptions',
        title: 'Subscriptions',
      },
      {
        image: reportingImage,
        isVisible: true,
        key: 'reporting',
        path: 'reporting/export-logins',
        title: 'Reporting',
      },
      {
        image: notificationImage,
        isVisible: services.includes(NetfrontServices.HOWLER) || services.includes(NetfrontServices.CAPUCHIN),
        key: 'notifications',
        path: 'notifications',
        title: 'Notifications',
      },
      {
        href: `${newEkardoUrl}/dashboard/${orgId}/${projectId}/social`,
        image: IconSocial,
        isVisible: services.includes(NetfrontServices.BONOBO),
        key: 'Social',
        title: 'Social',
      },
      {
        href: `${newEkardoUrl}/dashboard/${orgId}/${projectId}/group-access`,
        image: subImage,
        isVisible: services.includes(`${NetfrontServices.VERVET}`),
        key: 'group-access',
        title: 'Group access',
      },
      {
        href: `${newEkardoUrl}/dashboard/${orgId}/${projectId}/insight`,
        image: analytics,
        isVisible: services.includes(`${NetfrontServices.VERVET}`),
        key: 'insight',
        title: 'Insights',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identitySiteUrl, project, schedulerSiteUrl, newEkardoUrl]);

  const { name: projectName } = project ?? {};
  const pageTitle = projectName ? `Manage project options` : '';

  return (
    <PageWrapper info={pageTitle} pageDescription={pageTitle} pageTitle={pageTitle} params={params}>
      <ProjectNavBar title={projectName} />
      <CardBox>
        {featureCards
          .filter(({ isVisible }) => isVisible)
          .map(({ href, image, key, path, title }) => (
            <MenuCard key={`feature-card-${key}`} href={href ?? `${pathname}/${path}`} name={title} src={image} />
          ))}
      </CardBox>
    </PageWrapper>
  );
}

export default ProjectConsolePage;
