import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const getImageMapRequest = gql`
  query getImageMap($imageMapId: Int!) {
    contentGroup {
      getContentGroup(contentGroupId: $imageMapId) {
        iconAssetId
        iconAsset {
          alt
          assetId
          contentType
          s3Key
        }
        id
        regions {
          alternativeImageId
          alternativeImage {
            alt
            contentType
            s3Key
          }
          contentGroupId
          contentPage {
            id
          }
          iconSize
          iconType
          id
          name
          x
          y
        }
        title
        type
      }
    }
  }
`;

const getImageMap = ({ imageMapId, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="no-cache"
    query={getImageMapRequest}
    variables={{ imageMapId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          Object.entries(data).length !== 0 && data.contentGroup.getContentGroup
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);
getImageMap.propTypes = {
  imageMapId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getImageMap;
