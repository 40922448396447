import React, { useContext, useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import PropTypes from 'prop-types';

import LeftNavBar from './LeftNavBar';
import { ContentItem, Icon } from './styled';

import AppContext from '../../context/AppContext';
import { useNewEkardoSiteUrls } from '../../hooks';
import IconSocial from '../../static/img/icon-social.svg';
import IconContent from '../../static/img/leftNavBar/Content.svg';
import IconLibrary from '../../static/img/leftNavBar/Library.svg';
import IconReporting from '../../static/img/leftNavBar/Reporting.svg';
import IconScheduler from '../../static/img/leftNavBar/Scheduler.svg';
import IconSubscription from '../../static/img/leftNavBar/subscription.svg';
import IconUsers from '../../static/img/leftNavBar/Users.svg';
import '../../styles/_fonts.css';

const ProjectNavBar = (props) => {
  const { title } = props;
  const { org, project, hasSubscriptions } = useContext(AppContext);
  const { services = [] } = project;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getIdentitySiteBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    const ekardoNewBaseUrl = getNewEkardoSiteBaseUrl();
    const identityBaseUrl = getIdentitySiteBaseUrl();

    const currentItems = [
      {
        id: 1,
        name: 'Content',
        src: IconContent,
        path: '/content',
        baseUrl: ekardoNewBaseUrl,
        isVisible: services.includes('EKARDO'),
      },
      {
        id: 2,
        name: 'Library',
        src: IconLibrary,
        path: '/library',
        baseUrl: ekardoNewBaseUrl,
        isVisible: services.includes('EKARDO'),
      },
      {
        id: 4,
        name: 'Reporting',
        src: IconReporting,
        baseUrl: '',
        path: '/reporting/export-logins',
        isVisible: true,
      },
      {
        id: 6,
        name: 'Users',
        src: IconUsers,
        path: '',
        baseUrl: identityBaseUrl,
        isVisible: true,
      },
      {
        id: 8,
        name: 'Subscriptions',
        src: IconSubscription,
        path: '/subscriptions',
        baseUrl: ekardoNewBaseUrl,
        isVisible: hasSubscriptions,
      },
      {
        id: 10,
        name: 'Notifications',
        src: IconScheduler,
        path: '/notifications',
        baseUrl: ekardoNewBaseUrl,
        isVisible: services.includes('HOWLER'),
      },
      {
        id: 11,
        name: 'Social',
        src: IconSocial,
        path: '/social',
        baseUrl: ekardoNewBaseUrl,
        isVisible: services.includes('BONOBO_MANAGEMENT'),
      },
    ];

    setItems(currentItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const menuItems = items
    .filter((item) => item.isVisible)
    .map((item) => (
      <a
        key={item.id}
        href={`${
          item.name === 'Users'
            ? `${item.baseUrl}/project/${project.id}/user`
            : `${item.baseUrl}/dashboard/${org.key}/${project.id}${item.path}`
        }`}
      >
        <ContentItem>
          <Icon alt="icon" src={item.src} />
          <p>{item.name}</p>
        </ContentItem>
      </a>
    ));
  return (
    <LeftNavBar title={title} hasUserType>
      <div>{menuItems}</div>
    </LeftNavBar>
  );
};

ProjectNavBar.propTypes = {
  title: PropTypes.string,
};

ProjectNavBar.defaultProps = {
  title: 'Projects',
};
export default ProjectNavBar;
