import gql from 'graphql-tag';

export const SEARCH_CONTENT_GROUPS = gql`
  query SearchContentGroups($projectId: String!, $title: String) {
    contentGroup {
      searchContentGroups(
        projectId: $projectId
        status: [UNPUBLISHED, PUBLISHED]
        type: [
          LEARNING
          RESOURCE
          PAGE
          NEWS
          BLOG
          EVENTS
          HELP
          CONSENT
          QUESTIONNAIRE
        ]
        title: $title
      ) {
        id
        title
        type
        description
        url
        releaseDate
        estimatedTime
        iconAsset {
          contentType
          s3Key
        }
        status
        sort
        tags {
          id
          name
        }
      }
    }
  }
`;
