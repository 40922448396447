import gql from 'graphql-tag';

export const FRAGMENT_SUMMARY = gql`
  fragment summary on ContentSnippetSummaryType {
    id
    containerId
    __typename
    contentPageId
    contentSnippetFormId
    userFlowStepId
    contentSnippetQuestionId
    contentSnippetQuestion(fetchAnswers: false) {
      type: __typename
      mandatory
      sort
      question
      configuration {
        id
        ... on Match {
          description
          id
          randomise
          matchItems {
            id
            questionConfigurationMatchId
            sort
            text
          }
          responseSet {
            availableResponses {
              asset {
                id: assetId
                s3Key
                contentType
                alt
                fileName
                tags
                type
                xAxisFocus
              }
              id
              label
              questionResponseSetId
              value
              ... on QuestionResponseMatchType {
                correctPosition
              }
            }
            id
            projectId
            title
            type
          }
          type
          __typename
        }
        ... on SingleText {
          id
          maxLength
          minLength
          regex
          __typename
        }
        ... on Number {
          description
          id
          __typename
        }
        ... on MultiText {
          multiText: description
          id
          maxLength
          minLength
          regex
          messages {
            correct
            incomplete
            incorrect
          }
          __typename
        }
        ... on DropDownList {
          responseSet {
            id
            title
            availableResponses {
              id
              asset {
                assetId
                s3Key
                contentType
              }
              label
              questionResponseSetId
              value
            }
          }
        }
        ... on Checkbox {
          responseSet {
            id
            availableResponses {
              ... on QuestionResponseCheckboxType {
                id
                label
                value
                assetId
                scoreValue
              }
            }
          }
        }
        ... on MultiResponseText {
          id
          maxResponse
          minResponse
          multiResponseTextType: type
          __typename
        }
        ... on Radio {
          responseSet {
            id
            availableResponses {
              ... on QuestionResponseRadioType {
                id
                label
                questionResponseSetId
                value
                assetId
              }
            }
          }
        }
        type: __typename
      }
    }
  }
`;
