import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_CONTENT_PAGE = gql`
  mutation CreateContentPage(
    $contentGroupId: Int!
    $title: String!
    $url: String!
    $parentId: Int
    $seoTitle: String
    $assetId: String
    $seoDescription: String
    $tags: [Int]
    $contentPageType: Int
  ) {
    contentPage {
      createContentPage(
        contentPage: {
          contentGroupId: $contentGroupId
          title: $title
          url: $url
          parentId: $parentId
          assetId: $assetId
          seoTitle: $seoTitle
          seoDescription: $seoDescription
          tags: $tags
          contentPageType: $contentPageType
        }
      ) {
        id
        title
        url
        status
        sort
        parentId
        contentGroupId
        audio {
          appVersionId
          assetId
          fileName
          contentType
          fileSizeInBytes
          isPublic
          presignedUrl
          projectId
          publicUrl
          s3Key
          transcript
          uploaded
        }
        bubbleTexts {
          id
          text
          angle
          x
          y
          w
          shape
          assetId
          characterId
          hasShadow
          character {
            id
            name
            projectId
            __typename
          }
        }
        cssStyle {
          id
          name
        }
        mode
        tags {
          id
          name
        }
        contentPageType {
          id
          name
        }
        asset {
          assetId
          s3Key
          contentType
        }
        relatedPage {
          id
          title
          contentGroup {
            id
            title
          }
        }
        featured
        releaseDate
        seoDescription
        seoTitle
        contentEventsOnPage {
          contentEvents {
            animation
            animationDirection
            event
            eventDirection
            eventTitle
            id
            entityId
            infinite
            millisecondDelay
            millisecondSpeed
            isDefaultOpen
          }
          type
        }
        contentPageConfigurationNavigation {
          backButtonToolTip
          enableBackButton
          enableNextButton
          nextButtonToolTip
        }
      }
    }
  }
`;

const createContentPageRequest = ({
  contentGroupId,
  title,
  url,
  parentId,
  assetId,
  seoTitle,
  seoDescription,
  tags,
  contentPageType,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_CONTENT_PAGE}>
    {(createContentPage, { loading, error, data }) => {
      const variables = {
        contentGroupId,
        title,
        url,
        parentId,
        assetId,
        seoTitle,
        seoDescription,
        tags,
        contentPageType,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentPage.createContentPage.id, data.contentPage.createContentPage.sort, data.contentPage.createContentPage);
        return null;
      }

      return <MutationRequest isLoading={loading} mutation={createContentPage} variables={variables} />;
    }}
  </Mutation>
);

createContentPageRequest.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  assetId: PropTypes.string,
  contentPageType: PropTypes.number,
  parentId: PropTypes.number,
  seoDescription: PropTypes.string,
  seoTitle: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};

createContentPageRequest.defaultProps = {
  assetId: null,
  contentPageType: null,
  parentId: null,
  seoDescription: '',
  seoTitle: '',
  tags: [],
};

export default createContentPageRequest;
