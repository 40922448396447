import gql from 'graphql-tag';

export const GET_USERS = gql`
  query getUsers($after: String, $filter: String, $first: Int, $postedFrom: DateTime, $postedTo: DateTime) {
    user {
      getUsers(after: $after, filter: $filter, first: $first, postedFrom: $postedFrom, postedTo: $postedTo) {
        edges {
          cursor
          node {
            id
            ... on UserGraphType {
              id
              email
              firstName
              lastName
              postCount
              posts {
                assets {
                  assetId
                  contentType
                  fileName
                  s3Key
                }
                author {
                  id
                  displayedName
                  email
                  firstName
                  lastName
                  postCount
                }
                authorId
                commentCount
                comments {
                  edges {
                    node {
                      author {
                        displayedName
                        id
                      }
                      authorId
                      message
                      postId
                    }
                  }
                }
                createdDate
                createdDateTime
                id
                message
                tags
                topic {
                  id
                  title
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;
