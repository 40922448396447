import React, { useEffect, useState } from 'react';

import AudioControls from './AudioControls';
import CartoonAsset from './CartoonAsset';
import QuizButton from './QuizButton';
import QuizFeedback from './QuizFeedback';
import SpeechBubble, { TextMessage } from './SpeechBubble';

import { useContentPageState } from '../../context/contentPage/ContentPageContext';
import { useWindowSize } from '../../hooks/useWindowSize';
import { createKey } from '../../utils';
import './cartoonContent.css';

const CartoonContent = () => {
  const MULTI_RESPONSE_SYMBOL = '_::?::_';
  const IS_CORRECT_RESPONSE = '_::C::_';
  const QUIZ_QUESTION_SPLIT = '_::Q::_';
  const QUIZ_ANSWER_SPLIT = '_::N::_';
  const INFO_TYPE = '_::I::_';

  const [pageState] = useContentPageState();
  const { windowSize } = useWindowSize();

  const [isCartoonLoading, setIsCartoonLoading] = useState(true);
  const [hasQuizResponse, setHasQuizResponse] = useState(false);
  const [isLargerDevice, setIsLargerDevice] = useState(window.innerWidth);
  const [isInformationResponse, setIsInformationResponse] = useState(false);
  const [isMultiResponse, setIsMultiResponse] = useState(false);
  const [isQuizCorrect, setIsQuizCorrect] = useState(false);
  const [quizResponseFeedback, setQuizResponseFeedback] = useState('');
  const [selectedQuiz, setSelectedQuiz] = useState({
    index: null,
    text: '',
  });

  const getQuizIndex = (index) => String.fromCharCode('a'.charCodeAt(0) + index);

  const handleQuizSelect = (quizData, index, text, isCorrectResponse) => {
    let updatedIsQuizCorrect = false;

    if (isMultiResponse) {
      updatedIsQuizCorrect = isCorrectResponse;
    } else {
      updatedIsQuizCorrect = index + 1 === parseInt(quizData.parts[1], 10);
    }

    setHasQuizResponse(true);
    setIsQuizCorrect(updatedIsQuizCorrect);
    setSelectedQuiz({
      index,
      text,
    });
    setQuizResponseFeedback(updatedIsQuizCorrect ? quizData.parts[2] : quizData.parts[3]);
  };

  useEffect(() => {
    setIsLargerDevice(windowSize.width > 970);
  }, [windowSize]);

  const { asset, audio, bubbleTexts: bubbles = [] } = pageState.contentPage;
  const { contentType, s3Key, isAnimatedImage, presignedUrl: imageUrl } = asset || {};
  const { presignedUrl } = audio || {};
  const bubble = bubbles[0] || {};

  const isQuiz = bubble.shape === 'QUIZ';

  const quizData = {
    options: [],
    parts: [],
    question: '',
    style: undefined,
  };

  if (isQuiz) {
    const quizParts = pageState.contentPage.bubbleTexts[0].text.split(QUIZ_ANSWER_SPLIT);
    const quizOptions = [...quizParts[0].split(QUIZ_QUESTION_SPLIT)];

    quizData.options = quizOptions.slice(1);
    quizData.parts = quizParts;
    quizData.question = quizOptions[0];
    quizData.style = quizParts[4] ? JSON.parse(quizParts[4].replace(/'/g, '"')) : undefined;
  }

  const quizButtons = React.Children.toArray(
    quizData.options.map((text, index) => {
      const isCorrectResponse = text.includes(IS_CORRECT_RESPONSE);
      const textWithoutSymbol = text.replace(IS_CORRECT_RESPONSE, '');

      return (
        <QuizButton
          identifier={getQuizIndex(index)}
          text={textWithoutSymbol}
          onClick={() => handleQuizSelect(quizData, index, textWithoutSymbol, isCorrectResponse)}
        />
      );
    }),
  );

  const quizFeedback = (
    <QuizFeedback
      feedback={quizResponseFeedback}
      identifier={getQuizIndex(selectedQuiz.index)}
      isCorrect={isQuizCorrect}
      isInformation={isInformationResponse}
      text={selectedQuiz.text}
    />
  );

  const quizComponentToDisplay = hasQuizResponse ? quizFeedback : quizButtons;

  useEffect(() => {
    setIsInformationResponse(quizData.question.includes(INFO_TYPE));
    setIsMultiResponse(quizData.question.includes(MULTI_RESPONSE_SYMBOL));
  }, [quizData.question]);

  return (
    <>
      {asset ? (
        <section className="l-position--relative h-background--color-grey-300">
          <div className="l-position--relative h-with-2x-large-y-padding">
            <div className="l-position--relative h-with-y-margin">
              <div className="l-position--relative l-flex l-flex--align-center">
                {!isCartoonLoading && (
                  <>
                    {isQuiz && (
                      <>
                        <SpeechBubble
                          key={bubble.sort}
                          hasShadow={bubble.hasShadow}
                          left={bubble.x}
                          top={bubble.y}
                          type={bubble.shape}
                          width={bubble.w}
                        >
                          <strong className="h-color--brand-secondary">{quizData.question.split('1_::_')[1]}</strong>
                        </SpeechBubble>
                      </>
                    )}
                    {isLargerDevice && isQuiz && (
                      <div
                        className="c-quiz--desktop"
                        style={
                          quizData.style
                            ? {
                                left: `${quizData.style.x}%`,
                                top: `${quizData.style.y}%`,
                                width: `${quizData.style.w}%`,
                              }
                            : {
                                bottom: '1rem',
                                right: '2rem',
                                width: '40%',
                              }
                        }
                      >
                        {quizComponentToDisplay}
                      </div>
                    )}
                    {isLargerDevice &&
                      !isQuiz &&
                      bubbles.map(({ id, text, angle, y, x, shape, tailType, isTailVisible, w, hasShadow = false }) => (
                        <SpeechBubble
                          key={`speech-bubble-${id}`}
                          angle={angle}
                          bubbleText={text}
                          hasShadow={hasShadow}
                          isTailVisible={isTailVisible}
                          left={x}
                          tailType={tailType}
                          top={y}
                          type={shape}
                          width={w}
                        >
                          <div
                            className="c-quiz-button__text"
                            dangerouslySetInnerHTML={{
                              __html: text,
                            }}
                          />
                        </SpeechBubble>
                      ))}
                  </>
                )}
                {((s3Key && contentType) || imageUrl) && (
                  <CartoonAsset
                    contentType={contentType}
                    isAnimatedImage={isAnimatedImage ?? false}
                    presignedUrl={imageUrl}
                    s3Key={s3Key}
                    onLoadHandler={() => setIsCartoonLoading(false)}
                  />
                )}
              </div>
            </div>
            <AudioControls src={presignedUrl} />
            {!isLargerDevice && isQuiz && quizComponentToDisplay}
            {!isLargerDevice &&
              !isQuiz &&
              bubbles.map(({ text }, key) => (
                <TextMessage key={`small-screen-text-bubble-${createKey(text)}`} isReceived={key % 2 === 1} text={text} />
              ))}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default CartoonContent;
