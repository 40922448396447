import { useEffect } from 'react';

import { useCookie } from '@netfront/common-library';
import { useAuthentication, useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';

const LoginPage = () => {
  const { isAuthenticated } = useAuthentication();
  const { getLastUrlBeforeAuthenticationExpiredCookie, getRedirectAfterLoginUrlCookie } = useCookie();
  const { isDomainReady } = useDomain();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    if (!isAuthenticated) {
      window.location.href = getLoginUrl();
      return;
    }

    const lastPageVisitedBeforeCookieExpiredUrl = getLastUrlBeforeAuthenticationExpiredCookie();
    const redirectAfterLoginUrl = getRedirectAfterLoginUrlCookie();

    window.location.href = lastPageVisitedBeforeCookieExpiredUrl ?? redirectAfterLoginUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady]);

  return null;
};

export default LoginPage;
