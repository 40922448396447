import gql from 'graphql-tag';

export const FRAGMENT_SLIDER = gql`
  fragment slider on ContentSnippetSliderType {
    sliderId
    slider {
      animation
      arrows
      autoplay
      autoplaySpeed
      created
      dots
      infinite
      slidesToScroll
      slidesToShow
      type
      speed
      sliderItems {
        assetId
        asset {
          s3Key
          type
        }
        author
        id
        quote
        type
      }
      title
    }
  }
`;
