/* eslint-disable react/state-in-constructor */
import React, { Component, useState } from 'react';

import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';

import { getAssetsUrl } from './legacy/config';
import AppContext from './legacy/context/AppContext';
import ThemeContext from './legacy/context/ThemeContext';
import { getSignedUrl } from './legacy/middleware/AWS/getSignedUrl';
import client from './legacy/middleware/client';
import Routes from './Routes';

class AppProvider extends Component {
  state = {
    org: {},
    project: {},
    contentGroup: {},
    app: {},
    showRightSidebar: [false, '', null, null],
    isEditMode: true,
    hasSubscriptions: false,
    customSettingsType: '',
    actionsSidebarDetails: {},
    // isLoading: false,
  };

  updateOrg = (data) => {
    this.setState({
      org: data,
    });
  };

  updateProject = (data) => {
    this.setState({
      project: data,
    });
  };

  toggleSubscriptions = (hasSubscriptions) => {
    this.setState({ hasSubscriptions });
  };

  updateContentGroup = (data) => {
    this.setState({
      contentGroup: data,
    });
  };

  updateApp = (data) => {
    this.setState({
      app: data,
    });
  };

  handleShowSidebar = (value) => {
    this.setState({
      showRightSidebar: value,
    });
  };

  setCustomSettingsType = (type) => {
    this.setState({
      customSettingsType: type,
    });
  };

  setActionsSidebarDetails = (details) => {
    this.setState({
      actionsSidebarDetails: details,
    });
  };

  toggleIsEditMode = (state) => {
    this.setState({
      isEditMode: state,
    });
  };

  setIsLoading = () => {
    // this.setState({ isLoading });
  };

  render() {
    const {
      org,
      project,
      contentGroup,
      app,
      showRightSidebar,
      isEditMode,
      hasSubscriptions,
      customSettingsType,
      actionsSidebarDetails,
      // isLoading,
    } = this.state;
    const { children } = this.props;

    return (
      <AppContext.Provider
        value={{
          org,
          updateOrg: this.updateOrg,
          project,
          updateProject: this.updateProject,
          hasSubscriptions,
          toggleSubscriptions: this.toggleSubscriptions,
          contentGroup,
          updateContentGroup: this.updateContentGroup,
          app,
          updateApp: this.updateApp,
          showRightSidebar,
          handleShowSidebar: this.handleShowSidebar,
          isEditMode,
          toggleIsEditMode: this.toggleIsEditMode,
          customSettingsType,
          setCustomSettingsType: this.setCustomSettingsType,
          actionsSidebarDetails,
          setIsLoading: this.setIsLoading,
          setActionsSidebarDetails: this.setActionsSidebarDetails,
        }}
      >
        {/* eslint-disable-next-line max-len */}
        {/* commented out due to issues wiht the loading status not going away occasionally. */}
        {/* {isLoading && <Preloader />} */}
        {children}
      </AppContext.Provider>
    );
  }
}

const ThemeProvider = (props) => {
  const { children } = props;

  const [theme, setTheme] = useState({
    headerColor: 'transparent linear-gradient(65deg, #173567 0%, #972868 68%)',
    footerColor: '#2a2e2f',
    sideColor: 'transparent linear-gradient(203deg, #972868 0%, #403167 100%)',
    buttonColor: '#4bc2ca',
    linkColor: '#000',
    backGroundColor: '#fff',
  });

  const [logo, setLogo] = useState(`${getAssetsUrl()}/logo/logo-minimal.svg`);

  const handleUpdateTheme = (data) => {
    if (data) {
      setTheme({
        headerColor: data.headerColor || 'transparent linear-gradient(65deg, #173567 0%, #972868 68%)',
        footerColor: data.footerColor || '#2a2e2f',
        sideColor: data.sideColor || 'transparent linear-gradient(203deg, #972868 0%, #403167 100%)',
        buttonColor: data.buttonColor || '#4bc2ca',
        linkColor: data.linkColor || '#000',
        backGroundColor: data.backGroundColor || '#fff',
      });
    }
  };

  const handleUpdateLogo = (data) => {
    if (typeof data === 'string') {
      setLogo(data);
    } else {
      const tmpURL = getSignedUrl(data.s3Key, data.contentType);
      // setLogo
      setLogo(tmpURL);
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        updateTheme: handleUpdateTheme,
        logo,
        updateLogo: handleUpdateLogo,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const App = () => (
  <AppProvider>
    <ThemeProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  </AppProvider>
);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
