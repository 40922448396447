import gql from 'graphql-tag';

export const GET_FEED_POSTS = gql`
  query getFeedPosts(
    $authorId: Int
    $after: String
    $first: Int
    $from: Date
    $message: String
    $tags: [String]
    $to: Date
    $topics: [Int]
  ) {
    post {
      getFeedPosts(
        authorId: $authorId
        after: $after
        first: $first
        from: $from
        message: $message
        tags: $tags
        to: $to
        topics: $topics
      ) {
        edges {
          cursor
          node {
            id
            ... on PostGraphType {
              assets {
                assetId
                contentType
                fileName
                s3Key
              }
              author {
                id
                displayedName
                email
                firstName
                lastName
                postCount
              }
              authorId
              commentCount
              comments {
                edges {
                  node {
                    author {
                      displayedName
                      id
                    }
                    authorId
                    message
                    postId
                  }
                }
              }
              createdDate
              createdDateTime
              id
              message
              tags
              topic {
                id
                title
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;
