import gql from 'graphql-tag';

export const GET_TRANSACTION_FOR_ORDER = gql`
  query GetTransactionForOrder($orderId: Long!) {
    transactions {
      getTransactionForOrder(orderId: $orderId) {
        billingAddress
        description
        id
        internalPaymentReference
        invoiceId
        modifiedAtUtc
        order {
          group {
            id
            name
          }
          guid
          id
          modifiedAtUtc
          projectId
          totalAmountExTaxInDollars
        }
        paymentProviderReference
        paymentProviderType
        primaryContactEmail
        primaryContactName
        receiptEmail
        s3Key
        salesTaxAmountInDollars
        salesTaxRate
        totalAmountExTaxInDollars
        totalAmountIncTaxInDollars
        s3Key
      }
    }
  }
`;
