/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useState, useEffect } from 'react';

import { useJwtDecoder, useCookie } from '@netfront/common-library';
import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { _ClearStorage } from '@netfront/gelada-react-shared';
import { AppHeader } from '@netfront/ui-library';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

import AvatarMenu from '../AvatarMenu/AvatarMenu';
import NotificationMenu from '../NotificationMenu/NotificationMenu';
import OrganisationMenu from '../OrganisationMenu/OrganisationMenu';

import AppContext from '../../context/AppContext';
import GetUserRequest from '../../middleware/User/getUserRequest';
import IconMarketplace from '../../static/img/icon-marketplace.svg';

LogRocket.init('sfzpuh/ekardoreact');
setupLogRocketReact(LogRocket);

const setRocketUser = (id, firstname, lastname, email) => {
  LogRocket.identify(id, {
    name: `${firstname} ${lastname}`,
    email,
    subscriptionType: 'pro',
  });
};

const ConsoleHeader = () => {
  const { getUserDataCookie } = useCookie();
  const { push } = useHistory();

  const { org, project } = useContext(AppContext);

  const { getDecodedJwt } = useJwtDecoder();
  const { getAccessTokenCookie } = useCookie();
  const { isDomainReady } = useDomain();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [identityUrl, setIdentityUrl] = useState(undefined);
  const [isCustomBuild, setIsCustomBuild] = useState(false);

  const [requestSent, setRequestSent] = useState(false);
  const [restrict, setRestrict] = useState(false);
  const [user, setUser] = useState(null);

  const cookies = new Cookies();
  const userDataCookie = getUserDataCookie();

  if (!restrict && isCustomBuild) {
    setRestrict(true);
  }

  if (userDataCookie && !user) {
    const userData = JSON.parse(userDataCookie);

    const { email, firstName, id, lastName } = userData;

    setRequestSent(true);
    setRocketUser(id, firstName, lastName, email);
    setUser(userData);
  }

  const onError = () => {
    setRequestSent(true);
    _ClearStorage();
    cookies.remove('customBuild', { path: '/' });
    push('/');
  };

  const onUserData = (email, firstname, id, lastname) => {
    setRequestSent(true);
    setUser({
      email,
      firstname,
      id,
      lastname,
    });
    setRocketUser(id, firstname, lastname, email);
  };

  useEffect(() => {
    const accessToken = getAccessTokenCookie();
    if (!accessToken) return;
    const decoded = getDecodedJwt(accessToken);
    if (decoded.permissions.length === 0) {
      window.location.href = '/unauthorized';
    }
    const claim = decoded.custom_build;
    setIsCustomBuild(Boolean(claim));
  }, []);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(getBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <AppHeader
      logo={{
        href: `${identityUrl}/dashboard`,
        iconId: 'id_ekardo_icon',
        imageSrc: project.logo?.presignedUrl,
      }}
    >
      <Link
        style={{ marginRight: 'auto' }}
        to={
          // eslint-disable-next-line no-nested-ternary
          restrict ? `/dashboard/${org.key}/${project.id}` : `${identityUrl}/dashboard`
        }
      />
      {!restrict && (
        <a
          className="c-app-header__marketplace-link"
          href={Object.keys(project).length > 0 ? `/marketplace/${project.id}` : '/marketplace'}
        >
          <img alt="marketplace" src={IconMarketplace} />
        </a>
      )}

      {/* Handles notification component in console header */}
      <NotificationMenu />

      {/* Handles ability to change organisation */}
      {Object.keys(org).length !== 0 && <OrganisationMenu />}

      {/* Avatar handles account navigation */}
      {user && <AvatarMenu user={user} />}

      {!requestSent && <GetUserRequest onError={onError} onSuccessResult={onUserData} />}
    </AppHeader>
  );
};

export default ConsoleHeader;
