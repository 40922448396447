import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import {
  AppFragment,
  AudioFragment,
  ButtonFragment,
  CodeFragment,
  ContentSnippetSliderFragment,
  DocumentFragment,
  EmbedFragment,
  FormFragment,
  ImageFragment,
  MapFragment,
  QuestionFragment,
  QuoteFragment,
  RegionsFragment,
  SpacerFragment,
  TextFragment,
  VideoFragment,
} from '../ContentSnippet/Fragments/Collection';
import QueryRequest from '../QueryRequest';
import { SliderFragment, SummaryFragment } from '../QuestionConfiguration/Fragments/Collection';

export const GET_ALL_CONTENT_SECTIONS = gql`
  query GetContentSections($contentPageId: Int!) {
    contentSection {
      searchContentSections(contentPageId: $contentPageId, status: [ACTIVE]) {
        id
        contentPageId
        contentLayoutId
        sort
        backgroundColor
        visible
        borderColor
        cssStyle {
          id
          name
        }
        background {
          assetId
          contentType
          s3Key
        }
        style
        contentEvent {
          id
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          asset {
            assetId
            contentType
            s3Key
          }
          backgroundColor
          borderColor
          titleColor
          style
          triggerStyle
          iconHorizontalAlignment
          isDefaultOpen
        }
        sectionContainers {
          id
          sort
          identifier
          sectionId
          backgroundColor
          borderColor
          cssStyle {
            id
            name
          }
          contentEventId
          contentEvent {
            animation
            animationDirection
            entityId
            event
            eventDirection
            eventTitle
            infinite
            millisecondDelay
            millisecondSpeed
            asset {
              assetId
              contentType
              s3Key
            }
            backgroundColor
            borderColor
            titleColor
            style
            triggerStyle
            iconHorizontalAlignment
            isDefaultOpen
          }
          snippets {
            id
            sort
            visible
            cssStyle {
              id
              name
            }
            ... on ContentSnippetAvatarType {
              avatar {
                configuration {
                  key
                  value
                }
                imageUrl
              }
            }
            borderColor
            backgroundColor
            type: __typename
            __typename
            ...app
            ...audio
            ...button
            ...code
            ...document
            ...embed
            ...form
            ...image
            ...map
            ...question
            ...quote
            ...regions
            ...slider
            ...spacer
            ...summary
            ...text
            ...video
          }
        }
      }
    }
  }
  ${AppFragment}
  ${AudioFragment}
  ${ButtonFragment}
  ${CodeFragment}
  ${ContentSnippetSliderFragment}
  ${DocumentFragment}
  ${EmbedFragment}
  ${FormFragment}
  ${ImageFragment}
  ${MapFragment}
  ${QuestionFragment}
  ${QuoteFragment}
  ${RegionsFragment}
  ${SliderFragment}
  ${SpacerFragment}
  ${SummaryFragment}
  ${TextFragment}
  ${VideoFragment}
`;

const getContentSections = ({ contentPageId, onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_ALL_CONTENT_SECTIONS} variables={{ contentPageId }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.contentSection.searchContentSections}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getContentSections.propTypes = {
  contentPageId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getContentSections;
